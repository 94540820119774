<template>
  <card icon="icon" @goTo="goTo('/managesadaqah')" :hasEntries="countDailySadaqahDonations" :isLoading="countDailySadaqahDonationsStatus !== 'ready'" >
    <IconDailySadaqah color="gold" size="size28" />
    <template v-slot:heading>
      My Daily Sadaqa <span v-if="countDailySadaqahDonations">({{countDailySadaqahDonations}})</span>
    </template>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Card: defineAsyncComponent(() => import('@/views/ProfileNavCard.vue')),
    IconDailySadaqah: defineAsyncComponent(() => import('@/components/icons/IconDailySadaqah.vue'))
  },
  name: 'SummaryCountCard',
  async mounted () {
    await this.getDailySadaqahDonationsCount()
  },
  methods: {
    ...mapActions([
      'getDailySadaqahDonationsCount'
    ]),
    goTo (val) {
      this.$router.push(val)
    }
  },
  computed: {
    ...mapGetters([
      'countDailySadaqahDonations',
      'countDailySadaqahDonationsStatus'
    ])
  }
}
</script>
